@use 'styles/base/mixins';

.items {
  @include mixins.breakpoint(xs-only) {
    display: flex;
    gap: var(--spacing-03);
    max-width: calc(100vw - var(--spacing-06));
    margin: 0 calc(var(--spacing-06) * -1);
    overflow-x: scroll;
    -ms-overflow-style: none;
    overscroll-behavior-x: contain;
    scroll-padding: var(--spacing-06);
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include mixins.breakpoint(s) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: var(--spacing-10);
    align-items: baseline;
    height: 100%;
  }
}

.item {
  position: relative;
  display: grid;
  gap: var(--spacing-03);

  @include mixins.breakpoint(xs-only) {
    flex-shrink: 0;
    width: 270px;
    scroll-snap-align: start;

    &:first-of-type {
      margin-left: var(--spacing-06);
    }

    &:last-of-type {
      margin-right: var(--spacing-06);
    }
  }
}

.link {
  @include mixins.stretched-link;
}

.title {
  @include mixins.text-medium;

  @include mixins.breakpoint(l) {
    @include mixins.text-large;
  }
}

.subtitle {
  @include mixins.text-small;

  color: #55565b;
}

.category {
  &::before {
    content: '•';
    padding: 0 var(--spacing-02);
  }
}
