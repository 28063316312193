.imageWrapper {
  position: relative;
  padding-bottom: var(--spacing-04);

  &:hover .playButton {
    opacity: 0.5;
  }
}

.image {
  width: 100%;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22%;
  height: 22%;
  padding: var(--spacing-02) 0 var(--spacing-02) var(--spacing-02);
  color: var(--white);
  background-color: var(--black);
  border-radius: 5px;
  opacity: 0.7;
  transition: opacity 250ms ease-in-out 0s;
  transform: translate(-50%, -50%);
}
