@use 'styles/base/mixins';

.breadcrumb {
  padding: 0 var(--spacing-04) var(--spacing-04);
  white-space: nowrap;

  @include mixins.breakpoint(xs-only) {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include mixins.breakpoint(s) {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  @include mixins.breakpoint(xl) {
    padding: 0 0 var(--spacing-04);
  }
}

.link {
  &:hover {
    color: var(--gray-dark);
  }
}

.item {
  display: inline;

  & + & {
    &::before {
      content: '>';
      margin: 0 var(--spacing-02);
    }
  }

  &:last-of-type {
    .link {
      font-weight: 700;
    }
  }
}
