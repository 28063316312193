@use 'styles/base/mixins';

.slideInfo {
  display: grid;
}

.slideCounter,
.slideCredits {
  display: inline;
  color: var(--gray-dusky);
}

.slideCounter {
  margin-top: var(--spacing-06);
  font-weight: 700;
}

.slideTitle {
  margin: 0 var(--spacing-08);
  color: var(--white);
  font-weight: normal;
  line-height: 25px;
}

.left {
  padding-right: 5px;
}

.right {
  padding-left: 5px;
}

.galleryIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: var(--black-light);
  border-radius: 400px;

  @include mixins.devices(tablet) {
    width: 40px;
    height: 40px;
  }
}

.button {
  margin: 10px;
  padding-bottom: 100px !important;
  /* stylelint-disable custom-property-pattern */
  color: var(--yarl__color_button, hsl(0deg 0% 100% / 80%));
  background-color: var(--yarl__button_background_color, transparent);
  /* stylelint-enable custom-property-pattern */
  filter: none;
  line-height: 0;
  cursor: pointer;
  /* stylelint-disable-next-line custom-property-pattern */
  border: var(--yarl__button_border, 0);
  outline: none;
  appearance: none;

  @include mixins.devices(tablet) {
    margin: 0 60px;
  }

  &:disabled {
    /* stylelint-disable-next-line custom-property-pattern */
    color: var(--yarl__color_button_disabled, hsl(0deg 0% 100% / 40%));
    cursor: default;
  }
}

.slide {
  display: grid;
  padding: 0;

  @include mixins.devices(tablet) {
    padding: 0 180px;
  }

  img {
    justify-self: center;
    width: 100%;
    max-width: 1200px;
  }
}
