.root {
  position: relative;
}

.video {
  width: 100%;
}

.hide {
  display: none;
}

.errorMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: var(--spacing-04);
  color: var(--white);
  background: rgb(var(--black-rgb) / 80%);
  text-align: center;
  transform: translate(-50%, -50%);
}
