@use 'styles/base/mixins';

.topics {
  display: grid;
  gap: var(--spacing-16);
}

.nav {
  display: flex;
  gap: var(--spacing-05);
  flex-flow: row wrap;
  margin: 24px 0 48px;
  color: var(--black-text);
  font-weight: 400;

  @include mixins.text-xlarge;

  span {
    width: 34px;
  }

  span.disabled {
    opacity: 0.24;
  }

  @include mixins.breakpoint('l') {
    gap: unset;
    flex-wrap: nowrap;
    justify-content: space-between;

    span {
      width: unset;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(10px, 1fr));
  gap: var(--spacing-02) var(--spacing-04);
  margin-bottom: var(--spacing-08);

  @include mixins.breakpoint('xs-only') {
    grid-template-columns: none;
  }
}

.links {
  padding-bottom: var(--spacing-02);
  border-bottom: 1px solid var(--gray-bright);
}

.active {
  background-color: var(--gray-bright);
}
