@use 'styles/base/mixins';

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.logo {
  display: inline;
  margin-right: var(--spacing-02);
}

.title {
  @include mixins.text-small;

  flex: 1;
  margin-bottom: 0;
  padding: var(--spacing-05);
  font-weight: 400;
}

.chevron {
  padding: var(--spacing-05);
  color: var(--white);
  background-color: rgb(0 0 0 / 0%);
  cursor: pointer;
  border: none;
}

.flip {
  transform: scaleY(-1);
}

.closed {
  display: none;
}

.open {
  display: block;
}

.lowerLevel {
  margin-bottom: 2px;
}

.list {
  padding: 0;
}

.spacer {
  margin-bottom: var(--spacing-03);
}
