@use 'styles/base/mixins';

.section {
  display: flex;
  flex-direction: column;
}

.headline {
  @include mixins.line-clamp(3);

  font-size: 14px;
  line-height: 1.4;
}

.date {
  margin: 0;
  color: var(--gray-dark);
  line-height: 2;
}
