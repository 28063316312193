@use 'styles/base/mixins';

.section {
  position: relative;
}

.header {
  margin-bottom: var(--spacing-03);

  @include mixins.breakpoint(s) {
    min-height: 30px;
    margin-bottom: var(--spacing-04);
  }
}

.title {
  display: inline-block;
  padding: var(--spacing-01) var(--spacing-02);
  color: var(--white);

  &.links {
    background-color: var(--black);
  }

  &.news {
    background-color: var(--primary);
  }
}

.slider {
  display: flex;
  max-width: 100vw;
  margin: 0 calc(var(--spacing-04) * -1);
  overflow-x: scroll;
  -ms-overflow-style: none;
  overscroll-behavior-x: contain;
  scroll-padding: var(--spacing-04);
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mixins.breakpoint(s) {
    margin: 0 calc(var(--spacing-02) * -1);
    scroll-padding: 0;
  }
}

.arrow {
  position: absolute;
  top: 0;
  display: none;
  margin: 0;
  padding: 0;
  color: var(--black);
  background-color: transparent;
  cursor: pointer;
  border: 0;

  &.prev {
    right: var(--spacing-08);
  }

  &.next {
    right: 0;
  }

  @include mixins.breakpoint(s) {
    display: block;
  }

  &:disabled {
    color: var(--gray-light);
    cursor: default;
  }
}

.teaserWrapper {
  display: inline-flex;
  flex-flow: column wrap;
  flex-shrink: 0;
  width: 85%;
  height: auto;
  scroll-snap-align: start;

  @include mixins.breakpoint(xs-only) {
    &:first-of-type {
      margin-left: var(--spacing-04);
    }
  }

  @include mixins.breakpoint(s) {
    width: calc(100% / 3);
    scroll-snap-align: none;

    &:nth-of-type(3n + 1) {
      scroll-snap-align: start;
    }
  }
}

.teaser {
  height: 100%;
  margin-right: var(--spacing-04);
  padding: var(--spacing-03) var(--spacing-05);
  border: 1px solid var(--gray-bright);
  border-left: 5px solid var(--gray-light);

  @include mixins.breakpoint(s) {
    margin-right: var(--spacing-02);
    margin-left: var(--spacing-02);
    border: 0;
    border-left: 4px solid var(--gray-light);
  }

  &.links {
    background-color: var(--gray-bright);
  }

  &.news {
    border-left-color: var(--primary);

    @include mixins.breakpoint(s) {
      padding: 0 var(--spacing-08) 0 var(--spacing-03);
    }
  }
}
