@use 'styles/base/mixins';

.button {
  position: absolute;
  top: 0;
  z-index: 15;
  width: 44px;
  height: 121px;
  color: var(--white);
  cursor: pointer;
  border: none;

  &.buttonRight {
    right: 0;
    background: linear-gradient(to right, rgb(0 0 0 / 0%) 0%, var(--black) 100%);
  }

  &.buttonLeft {
    left: 0;
    background: linear-gradient(to right, var(--black) 0%, rgb(0 0 0 / 0%) 100%);
  }
}
