@use 'styles/base/mixins';

.teaser {
  position: relative;

  &.small {
    display: flex;
    gap: var(--spacing-03);
  }

  &.color {
    display: grid;
    gap: var(--spacing-03);
    padding: var(--spacing-06);

    @include mixins.breakpoint(s) {
      grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr);
      gap: var(--spacing-08);
      align-items: center;
    }

    @include mixins.breakpoint(l) {
      display: flex;
      gap: var(--spacing-10);
      padding: var(--spacing-08);
    }
  }
}

.imageWrapper {
  position: relative;
  height: 100%;
  background-color: var(--gray-light);

  .image {
    height: 100%;
  }

  .small & {
    flex-shrink: 0;
  }

  .medium:not(.highlight) &,
  .large:not(.highlight) & {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 20%, rgb(0 0 0 / 60%) 100%);
    }
  }

  .color & {
    flex-shrink: 0;
  }
}

.image {
  display: block;
  width: 100%;
  aspect-ratio: 3 / 4;
  object-fit: cover;

  .small & {
    width: 130px;

    @include mixins.breakpoint(l) {
      width: 137px;
    }
  }

  .large & {
    @include mixins.breakpoint(s) {
      aspect-ratio: 16 / 9;
    }
  }

  .color & {
    aspect-ratio: 1 / 1;

    @include mixins.breakpoint(l) {
      width: 549px;
      aspect-ratio: 16 / 9;
    }
  }
}

.link {
  @include mixins.stretched-link;
}

button.link {
  @include mixins.stretched-link;

  color: inherit;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  border: none;
}

.rtlPlusLogo {
  position: absolute;
  top: var(--spacing-06);
  left: var(--spacing-06);
}

.contentWrapper {
  letter-spacing: 0.4px;

  .medium &,
  .large & {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
    padding: var(--spacing-06);
    color: var(--white);

    &.paddingBottom {
      padding-bottom: var(--spacing-08);
    }
  }

  .color & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--white);

    @include mixins.breakpoint(l) {
      order: -1;
    }
  }
}

.title {
  .small & {
    @include mixins.text-medium;

    @include mixins.breakpoint(s) {
      hyphens: auto;
    }

    @include mixins.breakpoint(l) {
      font-size: 18px;
      line-height: 24.12px;
    }
  }

  .medium & {
    @include mixins.text-large;
  }

  .large & {
    @include mixins.text-large;

    @include mixins.breakpoint(s) {
      @include mixins.text-display-s;

      max-width: 30ch;
    }
  }

  .highlight & {
    padding-bottom: 6px;

    span {
      padding: 0 var(--spacing-02) var(--spacing-01);
      box-decoration-break: clone;
    }
  }

  .highlight.medium & {
    @include mixins.text-display-s;
  }

  .highlight.large & {
    @include mixins.text-display-s;

    @include mixins.breakpoint(s) {
      max-width: 50%;
      font-size: 32px;
      line-height: 38px;
    }
  }

  .color & {
    @include mixins.text-large;

    @include mixins.breakpoint(s) {
      @include mixins.text-display-s;
    }
  }
}

.subtitle {
  @include mixins.text-xsmall;

  margin-top: var(--spacing-03);
  padding: 5.5px 6px;

  .color & {
    order: 2;
    margin-top: var(--spacing-05);
    padding: 0;
  }
}

.button {
  @include mixins.text-xsmall;

  display: inline-flex;
  align-items: center;
  color: var(--black);
  background-color: var(--white);
  line-height: 1;

  &.rtlPlus {
    color: var(--white);
    background-color: var(--black);
  }

  .small & {
    position: absolute;
    bottom: var(--spacing-03);
    left: var(--spacing-03);
    height: 24px;
    padding: 0 var(--spacing-02) 0 var(--spacing-01);
  }

  .medium &,
  .large &,
  .color & {
    width: 100%;
    height: 36px;
    margin-top: var(--spacing-05);
    padding: 0 var(--spacing-03);
  }

  .large &,
  .color & {
    @include mixins.breakpoint(s) {
      width: auto;
    }
  }

  .iconGallery,
  .iconPlay {
    margin-right: var(--spacing-01);
  }

  .iconExternalLink {
    margin-right: var(--spacing-01);
    margin-left: auto;

    .large & {
      margin-left: var(--spacing-02);
    }
  }
}

.sponsored {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.galleryCount {
  @include mixins.text-xsmall;

  position: absolute;
  right: var(--spacing-06);
  bottom: var(--spacing-08);
  display: flex;
  gap: var(--spacing-01);
  align-items: center;
  color: var(--white);
}
