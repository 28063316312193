@use 'styles/base/mixins';

@keyframes swiper-slide-in {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 244px;
    opacity: 1;
  }
}

.root {
  position: relative;

  &.hidden:not(.animationOnShow) {
    z-index: -1;
    height: 0;
    opacity: 0;
  }

  &.animationOnShow {
    animation-name: swiper-slide-in;
    animation-duration: 1s;
  }
}

.title {
  @include mixins.text-medium;

  font-size: 700;

  @include mixins.breakpoint(xs-only) {
    margin-bottom: var(--spacing-04);
  }
}

.swiperThumbnails {
  position: relative;
  opacity: 1;
}

.disableSwiper {
  opacity: 0.2;
}

.scrollOverlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  width: 2%;
  height: 100%;

  @include mixins.breakpoint(l) {
    display: none;
  }
}

.swiperContainer {
  position: relative;

  .navButtons {
    display: none;
  }

  @include mixins.breakpoint(l) {
    &:hover .navButtons {
      display: block;
    }
  }
}

.scrollContainer {
  @include mixins.hide-scrollbar;

  box-sizing: border-box;
  display: flex;
  gap: var(--spacing-03);
  align-items: flex-start;
  margin: 0;
  padding: 0 var(--spacing-06) 0 0;
  overflow-x: scroll;
  list-style: none;
  scroll-snap-type: x mandatory;

  @include mixins.breakpoint(l) {
    gap: var(--spacing-05);
    padding-right: 0;
  }
}

.thumbnailWrapper {
  flex: 0 0 80%;
  scroll-snap-align: start;

  @include mixins.breakpoint(s) {
    flex: 0 0 45%;
  }

  @include mixins.breakpoint(l) {
    flex: 0 0 calc(25% - 15px);
  }
}

.adPlayingOverlayText {
  @include mixins.text-large;

  font-weight: 700;
}

.thumbnailOverlay {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--white);
}
