@use 'styles/base/mixins';

.contentWrapper {
  @include mixins.devices(mobile-only) {
    padding-bottom: var(--spacing-06);
    background-color: var(--gray-bright);
  }
}

.grid {
  @include mixins.devices(mobile-only) {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include mixins.devices(tablet) {
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 1fr));
    gap: var(--spacing-05);
  }

  @include mixins.devices(desktop) {
    grid-template-columns: minmax(10px, 3fr) minmax(10px, 1fr) minmax(10px, 1fr);
  }
}

.teaser {
  position: relative;

  @include mixins.devices(mobile-only) {
    flex-shrink: 0;
    width: 100%;
    padding: var(--spacing-04);
    scroll-snap-align: start;
  }

  @include mixins.devices(tablet) {
    background-color: var(--gray-bright);
  }

  &.first {
    @include mixins.devices(tablet-only) {
      grid-column: auto / span 2;
    }

    @include mixins.devices(tablet) {
      display: grid;
      grid-template-columns: minmax(10px, 1.5fr) minmax(10px, 1fr);
    }
  }
}

.imageWrapper {
  position: relative;
  flex-shrink: 0;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;

  .teaser.first & {
    height: 100%;
  }
}

.badge {
  position: absolute;
  bottom: var(--spacing-02);
  left: var(--spacing-03);
  z-index: 1;
  padding: var(--spacing-01) var(--spacing-02);
  background-color: var(--white);
}

.indicatorWrapper {
  display: none;

  @include mixins.devices(mobile-only) {
    display: flex;
    gap: var(--spacing-03);
    justify-content: center;
  }
}

.indicator {
  width: 3.375rem;
  height: 0.1875rem;
  background-color: var(--gray-light);
  border-radius: 2px;

  &.active {
    background-color: var(--black);
  }
}
