@use 'styles/base/mixins';

.background {
  @include mixins.devices(mobile-only) {
    section {
      > div {
        padding: var(--spacing-02);
        background-color: var(--gray-bright);
      }
    }
  }
}

.teaser {
  position: relative;
  background-color: var(--gray-bright);

  @include mixins.devices(mobile-only) {
    display: flex;
    align-items: center;
    background-color: var(--white);
  }
}

.imageWrapper {
  position: relative;
  flex-shrink: 0;
  background-color: var(--gray-bright);

  @include mixins.devices(tablet) {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-image: linear-gradient(0deg, var(--black), rgb(var(--black-rgb) / 0%) 50%);
    }
  }
}

.image {
  width: 100%;

  @include mixins.devices(mobile-only) {
    display: none;
  }
}

.fallbackImage {
  @include mixins.devices(mobile-only) {
    display: none;
  }
}

.number {
  color: var(--white);

  @include mixins.devices(mobile-only) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--spacing-14);
    height: var(--spacing-14);

    &.number1 {
      background-color: var(--black);
    }

    &.number2 {
      background-color: rgb(var(--black-rgb) / 90%);
    }

    &.number3 {
      background-color: rgb(var(--black-rgb) / 80%);
    }

    &.number4 {
      background-color: rgb(var(--black-rgb) / 70%);
    }

    &.number5 {
      background-color: rgb(var(--black-rgb) / 60%);
    }
  }

  @include mixins.devices(tablet) {
    position: absolute;
    bottom: 0;
    left: var(--spacing-02);
    z-index: 1;
    display: block;
  }
}

.link {
  @include mixins.stretched-link;
}

.headline {
  position: relative;

  @include mixins.devices(mobile-only) {
    @include mixins.line-clamp(1);

    font-size: 17px;
  }

  @include mixins.devices(tablet) {
    @include mixins.line-clamp(3);
  }
}

.textWrapper {
  padding: var(--spacing-04) var(--spacing-02);

  @include mixins.devices(mobile-only) {
    padding: 0 var(--spacing-03);
  }
}

.subtitle {
  margin-bottom: var(--spacing-01);
  color: var(--gray-dark);

  @include mixins.devices(mobile-only) {
    display: none;
  }
}
