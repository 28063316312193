@use 'styles/base/mixins';

.link {
  @include mixins.stretched-link;
}

.textWrapper {
  padding: var(--spacing-02) var(--spacing-03);

  @include mixins.devices(tablet) {
    .teaser.first & {
      padding: var(--spacing-04);
    }
  }
}

.date {
  margin-bottom: var(--spacing-02);

  @include mixins.devices(tablet) {
    margin-bottom: var(--spacing-01);

    .teaser.first & {
      margin-bottom: var(--spacing-02);
    }
  }
}

.headline {
  position: relative;

  @include mixins.devices(mobile-only) {
    font-size: 24px;
  }
}

.description {
  margin: var(--spacing-02) 0 0;
  line-height: 1.5;

  @include mixins.devices(tablet) {
    &:not(:global(.first-teaser) &) {
      display: none;
    }
  }
}
