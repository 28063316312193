@use 'styles/base/mixins';

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--white);
  background: transparent;
  cursor: pointer;
  border: none;
  outline-offset: -1px;
}

.poster {
  position: relative;
  margin-bottom: var(--spacing-04);
  pointer-events: none;
}

.duration {
  @include mixins.text-xsmall;

  position: absolute;
  bottom: var(--spacing-02);
  left: var(--spacing-02);
  display: flex;
  align-items: center;
  padding: var(--spacing-01) var(--spacing-02) var(--spacing-01) var(--spacing-01);
  color: var(--white);
  background-color: var(--black);

  & > svg {
    margin-right: var(--spacing-01);
  }
}

.activeIndicator {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgb(0.1 0.1 0.1 / 50%);
  border-top: solid 2px var(--white);
}

.title {
  @include mixins.text-medium;

  margin-bottom: var(--spacing-04);
  text-align: left;

  @include mixins.breakpoint(l) {
    margin-bottom: var(--spacing-08);
  }
}
