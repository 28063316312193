@use 'styles/base/mixins';

.teaser {
  position: relative;
  margin-bottom: calc(var(--spacing-03) * -1);
  background-color: var(--gray-bright);

  &.first {
    @include mixins.devices(tablet) {
      display: grid;
      grid-template-columns: minmax(10px, 2fr) minmax(10px, 1fr);
      grid-column: span 3;
    }

    @include mixins.devices(desktop) {
      margin-bottom: calc(var(--spacing-07) * -1);
    }
  }
}

.imageWrapper {
  position: relative;
  flex-shrink: 0;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;

  .teaser.first & {
    height: 100%;
  }
}

.badge {
  position: absolute;
  bottom: var(--spacing-02);
  left: var(--spacing-03);
  z-index: 1;
  padding: var(--spacing-01) var(--spacing-02);
  background-color: var(--white);
}
