@use 'styles/base/mixins';

.headline {
  margin: var(--spacing-06) 0;
}

.title {
  display: block;
  line-height: normal;
}

.separator {
  display: none;
}

.date {
  margin: var(--spacing-06) 0;

  @include mixins.devices(tablet) {
    margin: var(--spacing-06) 0;
  }
}

.content {
  margin-bottom: var(--spacing-06) 0 var(--spacing-16) 0;
}

.text {
  line-height: 28px;
}
