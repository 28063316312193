@use 'styles/base/mixins';

.background {
  @include mixins.breakpoint(xs-only) {
    section {
      > a {
        margin-bottom: 0;
      }

      > div {
        padding: var(--spacing-05);
        background-color: var(--gray-bright);
      }
    }
  }
}

.teaser {
  position: relative;
  background-color: var(--gray-bright);

  @include mixins.container(mobile-only) {
    display: flex;
    gap: var(--spacing-03);
    align-items: flex-start;
  }
}

.imageWrapper {
  position: relative;
  flex-shrink: 0;
  background-color: var(--gray-bright);

  @include mixins.container(mobile-only) {
    width: 124px;
  }
}

.image {
  width: 100%;
}

.link {
  @include mixins.stretched-link;
}

.h3 {
  position: relative;
}

.headline {
  line-height: 1.3;

  @include mixins.container(mobile-only) {
    font-size: 17px;
    line-height: 1.25;
  }
}

.textWrapper {
  padding: var(--spacing-04) var(--spacing-02);

  @include mixins.container(mobile-only) {
    padding: var(--spacing-02) 0;
  }
}
