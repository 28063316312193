@use 'styles/base/mixins';

.adslot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: var(--spacing-05) var(--spacing-06);
  background-color: #f2f2f2;
}
