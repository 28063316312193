@use 'styles/base/mixins';

.root {
  display: grid;

  @include mixins.breakpoint('xs-only') {
    margin-inline: calc(var(--spacing-06) * -1);
    overflow-x: scroll;
    scroll-padding: var(--spacing-06);
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.grid {
  margin: 0;
  padding: 0;
  list-style: none;

  @include mixins.breakpoint('xs-only') {
    display: flex;
    gap: var(--spacing-03);
    justify-content: space-between;
  }

  @include mixins.breakpoint('s') {
    display: grid;
  }

  &.image1_1 {
    @include mixins.breakpoint('s') {
      grid-template-columns: repeat(4, minmax(10px, 1fr));
      gap: var(--spacing-08);
    }

    @include mixins.breakpoint('l') {
      gap: var(--spacing-10);
    }
  }

  &.image3_4 {
    @include mixins.breakpoint('s') {
      grid-template-columns: repeat(6, minmax(10px, 1fr));
      gap: var(--spacing-04);
    }

    @include mixins.breakpoint('l') {
      gap: var(--spacing-10);
    }
  }
}

.item {
  @include mixins.breakpoint('xs-only') {
    scroll-snap-align: start;

    &:first-of-type {
      margin-left: var(--spacing-06);
    }

    &:last-of-type {
      margin-right: var(--spacing-06);
    }
  }
}

.teaser {
  display: flex;
  gap: var(--spacing-03);
  flex-direction: column;
  width: 130px;

  .image1_1 & {
    @include mixins.breakpoint('s') {
      width: 138px;
    }

    @include mixins.breakpoint('l') {
      flex-direction: row;
      width: auto;
    }
  }

  .image3_4 & {
    @include mixins.breakpoint('s') {
      width: auto;
    }
  }
}

.image {
  width: 100%;
  height: auto;

  .image1_1 & {
    @include mixins.breakpoint('xs-only') {
      width: 130px;
    }

    @include mixins.breakpoint('l') {
      width: 100px;
    }
  }
}

.text {
  @include mixins.text-small;

  display: flex;
  gap: var(--spacing-03);
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .image1_1 & {
    font-weight: 700;
  }
}
