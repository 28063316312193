@use 'styles/base/mixins';

.section {
  container-type: inline-size;
}

.grid {
  display: grid;
  gap: var(--spacing-08);

  @include mixins.breakpoint(l) {
    gap: var(--spacing-10);
  }

  &.default {
    @include mixins.devices(tablet) {
      grid-template-columns: repeat(2, minmax(10px, 1fr));

      > div[data-id='lead'] {
        grid-column: span 2;
      }
    }

    @include mixins.breakpoint(l) {
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    }
  }

  &.smallAufmacher {
    @include mixins.breakpoint(s) {
      grid-template-columns: repeat(2, minmax(10px, 1fr));

      > div[data-id='lead'] {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    @include mixins.breakpoint(l) {
      grid-template-columns: repeat(3, minmax(10px, 1fr));
    }
  }

  &.smallWithMedium {
    align-items: start;

    @include mixins.breakpoint(s) {
      grid-template-columns: repeat(2, minmax(10px, 1fr));

      > div:first-of-type {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    @include mixins.breakpoint(l) {
      grid-template-columns: repeat(3, minmax(10px, 1fr));

      > div:first-of-type {
        grid-column: unset;
      }
    }
  }

  &.rtlPlus {
    @include mixins.breakpoint(l) {
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    }
  }

  &.rtlPlusTop5 {
    row-gap: var(--spacing-02);

    @include mixins.breakpoint(s) {
      grid-template-columns: repeat(5, minmax(10px, 1fr));
      column-gap: var(--spacing-04);
    }
  }

  > div:empty {
    display: none;
  }
}
