@use 'styles/base/mixins';

.series {
  display: grid;
  gap: var(--spacing-16);
}

.nav {
  display: flex;
  gap: var(--spacing-05);
  flex-flow: row wrap;
  margin: 24px 0 48px;
  color: var(--black-text);

  &.rebrush {
    font-weight: 400;

    @include mixins.text-xlarge;

    span {
      width: 34px;
    }

    span.disabled {
      opacity: 0.24;
    }

    @include mixins.breakpoint('l') {
      gap: unset;
      flex-wrap: nowrap;
      justify-content: space-between;

      span {
        width: unset;
      }
    }
  }
}

.nav:not(.rebrush) {
  @include mixins.devices('mobile-only') {
    flex-wrap: nowrap;
    overflow-x: scroll;

    a {
      white-space: nowrap;
    }
  }

  a {
    color: var(--gray-dark);
    font-weight: 700;
  }
}

.items {
  display: flex;
  flex-direction: column;
  scroll-margin-top: 158px;

  &.newscroll {
    scroll-margin-top: 84px;
  }
}

.rebrush {
  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @include mixins.breakpoint('xs-only') {
      gap: var(--spacing-10);
      flex-direction: column;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 144px);
    gap: var(--spacing-08) var(--spacing-03);
    width: 496px;

    @include mixins.breakpoint('l') {
      grid-template-columns: repeat(4, 160px);
      justify-content: space-between;
      width: 760px;
    }

    @include mixins.breakpoint('xs-only') {
      grid-template-columns: repeat(2, 158px);
      width: 328px;
    }
  }

  .letter {
    font-weight: 700;

    @include mixins.text-display-s;

    @include mixins.breakpoint('l') {
      @include mixins.text-display-m;
    }
  }

  a {
    display: flex;
    gap: var(--spacing-03);
    flex-direction: column;
  }

  .kicker {
    @include mixins.text-medium;

    color: var(--black-text);
    font-weight: 400;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(10px, 1fr));
  gap: var(--spacing-10);

  @include mixins.devices('mobile-only') {
    grid-template-columns: none;
  }
}

.image {
  width: 100%;
  height: auto;
}
