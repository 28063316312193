@use 'styles/base/mixins';

.contentWrapper {
  overflow: hidden;
  transition: height 300ms ease-out;
}

.content {
  padding: var(--spacing-04);
  background-color: var(--gray-bright);
}

.button {
  all: unset;
  display: flex;
  gap: var(--spacing-01);
  align-items: center;
  padding: var(--spacing-02) 0;
  cursor: pointer;
}

.buttonText {
  text-decoration: underline;
}

.rotate {
  transform: rotate(180deg);
}
